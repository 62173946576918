





















































































































































































































































































































































































































































































































































































































































































































































































































































































.swal2-actions {
  gap: 10px;
  display: flex;
  .swal2-cancel {
    margin-bottom: 0;
  }
  .swal2-confirm {
    border: 1px solid #3498db;
  }
}
.school_teachers_page_wrapper {
  .el-dialog__header {
    text-align: initial !important;
    text-transform: capitalize;
  }
  .el-form-item__label {
    width: 100%;
    text-transform: capitalize;
    text-align: initial !important;
  }
  .el-form-item__error {
    width: 100%;
    text-align: initial;
  }
}
